import ForkLaneService from "@/services/fork-lane.service";
import router from "@/router";
import getCookie from "@/lib/get-cookie";
import setCookie from '@/lib/set-cookie.js';

const user = getCookie('user');
const node = getCookie('nodeToken', false);

const initialState = {
    user: user ? user : null,
    node: node ? node : null,
    dpdhl: {
        portokasse: {
            token: null,
            wallet: null
        }
    }

};

export const ForkLaneModule = {
    namespaced: true,
    state: initialState,
    actions: {
        loginNode({commit}) {
            return new Promise((resolve, reject) => {
                if (node) {
                    commit('nodeTokenSuccess', node);
                    resolve('Node token successfully acquired');
                    return
                }
                ForkLaneService.loginNode()
                    .then(data => {
                        setCookie('nodeToken', data.token, {expires: data.expiresInSeconds})
                        commit('nodeTokenSuccess', data.token);
                        resolve('Node token successfully acquired');
                    })
                    .catch(error => {
                        commit('nodeTokenFailure');
                        reject(error);
                    })
            })
        },
        loginUser({ commit }, data) {
            return new Promise((resolve, reject) => {
                ForkLaneService.loginUser(data.username, data.password, data.nodeToken)
                    .then(async user => {
                        if (user === null) {
                            commit('loginFailure');
                            await router.push({ name: 'sign-in' });
                            return;
                        }

                        setCookie('user', JSON.stringify(user), {expires: 86400})
                        commit('loginSuccess', user);
                        await router.push({ name: 'dashboard-overview' });
                        resolve(user);
                    })
                    .catch(error => {
                        commit('loginFailure');
                        reject(error);
                    });
            });
        },
        async logout({ commit }) {
            document.cookie = 'user=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            commit('logout');
            await router.push({ name: 'sign-in' });
        },
        loginDeutschePostUser({ commit }) {
            return new Promise((resolve, reject) => {
                ForkLaneService.loginDeutschePostUser()
                    .then(response => {
                        commit('deutschePostLoginSuccess', response);
                        resolve(response)
                    })
                    .catch(error => {
                        commit('deutschePostLoginFailure');
                        reject(error);
                    })
            })
        },
        topUpDetuschePost({ dispatch, commit }, payload) {
            return new Promise((resolve, reject) => {
                ForkLaneService.topUpDetuschePost(payload.authToken, payload.balance, payload.amountInCent)
                .then(amount => {
                    commit('topUpDetuschePostSuccess', amount);
                    const payload = {
                        title: 'Topped-Up Successfully',
                        message: 'You topped up your wallet successfully'
                    }
                    dispatch('toast/success', payload, {root: true})
                    resolve(amount)
                })
                .catch(error => {
                    reject(error);
                })
            })
        }
    },
    mutations: {
        nodeTokenSuccess(state, token) {
            state.node = token;
        },
        nodeTokenFailure(state) {
            state.node = null;
        },
        loginSuccess(state, user) {
            state.user = user;
        },
        loginFailure(state) {
            state.user = null;
        },
        logout(state) {
            state.user = null;
        },
        deutschePostLoginSuccess(state, data) {
            state.dpdhl.portokasse.token = data.userToken;
            state.dpdhl.portokasse.wallet = data.walletBalance;
        },
        deutschePostLoginFailure(state) {
            state.dpdhl.portokasse.token = null;
            state.dpdhl.portokasse.wallet = null;
        },
        topUpDetuschePostSuccess(state, amount) {
            state.dpdhl.portokasse.wallet = amount;
        }
    }
};