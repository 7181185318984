import axios from "axios";
import getRequestHeaders from "@/lib/get-request-headers";
import createInfoObject from "@/lib/create-info-object";

export default new class ForkLaneService {
    constructor() {
        this.nodeUniqueId = process.env.VUE_APP_NODE_UNIQUE_ID,
        this.url = process.env.VUE_APP_SERVICE_URL + '/' + process.env.VUE_APP_SERVICE_PORTFOLIO + '/info/' + process.env.VUE_APP_SERVICE_VERSION;
    }

    loginNode() {
        return new Promise((resolve, reject) => {
            const data = {
                nodeUniqueId: process.env.VUE_APP_NODE_UNIQUE_ID,
                nodePortfolioElementUniqueId: process.env.VUE_APP_NODE_PORTFOLIO_ELEMENT_UNIQUE_ID
            }
    
            const requestData = createInfoObject(
                "OPEN_NODE_LOGIN_NODE",
                null,
                "",
                data
            );
    
            const requestConfig = {
                headers: getRequestHeaders()
            }

            axios.post(this.url, requestData, requestConfig)
                .then(response => {
                    if (response.status === 200) {
                        resolve (response.data.data);
                    }
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    loginUser(username, password, nodeToken) {
        return new Promise((resolve, reject) => {

            const auth = {
                headers: { Authorization: 'Bearer ' + nodeToken }
            };

            const data = { 
                username: username,
                password: password 
            };

            const requestData = createInfoObject(
                "PUBLIC_USER_LOGIN_USER",
                null,
                "",
                data
            );

            axios.post(this.url, requestData, auth)
                .then(response => {
                    let user = null;

                    if (response.status === 200) {
                        user = {
                            firstName: response.data.data.firstName,
                            lastName: response.data.data.lastName,
                            email: response.data.data.email,
                            username: response.data.data.username,
                            token: response.data.data.token,
                            tokenExpiresInSeconds: 86400
                        }
                    }
                    resolve(user);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    loginDeutschePostUser() {
        return new Promise((resolve, reject) => {

            const requestConfig = {
                headers: getRequestHeaders()
            }

            const data = {
                externalSystem: 'DEUTSCHE_POST',
                externalData: {
                    username: 'pcf_00239@zq4nnzgbnbvt3.webpage.t-com.de',
                    password: 'XQ6sLsaA3'
                }
            }

            const requestData = createInfoObject(
                "USER_EXTERN_LOGIN_USER",
                null,
                "",
                data
            );

            axios.post(this.url, requestData, requestConfig)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data.data)
                    } else {
                        reject('Unable to get user data')
                    }
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                })
        })
    }
    topUpDetuschePost(authToken, balance, amountInCent) {
        return new Promise((resolve, reject) => {
            const requestConfig = {
                headers: getRequestHeaders()
            }
    
            const data = {
                externalSystem: 'DEUTSCHE_POST',
                externalData: {
                    authToken,
                    balance,
                    amountInCent
                }
            }

            const requestData = createInfoObject(
                "USER_EXTERN_TOP_UP",
                null,
                "",
                data
            );

            axios.post(this.url, requestData, requestConfig)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data.data.data.balanceInCent)
                } else {
                    reject('Unable to top-up amount.')
                }
            })
            .catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
}