import Vue from "vue";
import Vuex from "vuex";
import { ForkLaneModule } from "@/modules/fork-lane.module";
import { SidebarModule } from "@/modules/sidebar.module"
import { ToastModule } from "../modules/toast.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    forkLane: ForkLaneModule,
    sidebar: SidebarModule,
    toast: ToastModule
  }
})
