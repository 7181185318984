
export default function setCookie(name, value, options) {
    const opt = options || {};
    let str = name + "=" + value;
    if (!opt.path) {
        opt.path = '/';
    }
    if (opt.expires) {
        let dateObj = new Date();
        dateObj.setSeconds(dateObj.getSeconds() + opt.expires);
        opt.expires = dateObj;
        str += '; Expires=' + opt.expires.toUTCString();
    }
    str += '; Path=' + opt.path;
    document.cookie = str;
}