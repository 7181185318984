import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true,
      title: 'Home'
    }
  },
  {
    path: '/dashboard/overview',
    name: 'dashboard-overview',
    component: () => import(/* webpackChunkName: "dashboardOverview" */ '../pages/dashboard/PageOverview'),
    meta: {
      title: 'Overview',
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/billing',
    name: 'dashboard-billing',
    component: () => import(/* webpackChunkName: "dashboardBilling" */ '../pages/dashboard/PageBilling'),
    meta: {
      title: 'Billing',
      requiresAuth: true
    }
  },
  {
    path: '/dashboard/shipping',
    name: 'dashboard-shipping',
    component: () => import(/* webpackChunkName: "dashboardShipping" */ '../pages/dashboard/PageShipping'),
    meta: {
      title: 'Shipping',
      requiresAuth: true
    }
  },
  {
    path: '/delivery/sales-orders',
    name: 'delivery-sales-orders',
    component: () => import(/* webpackChunkName: "deliverySalesOrders" */ '../pages/delivery/PageSalesOrders'),
    meta: {
      title: 'Delivery',
      requiresAuth: true
    }
  },
  {
    path: '/delivery/delivery-notes',
    name: 'delivery-notes',
    component: () => import(/* webpackChunkName: "deliveryNotes" */ '../pages/delivery/PageDeliveryNotes'),
    meta: {
      title: 'Delivery Notes',
      requiresAuth: true
    }
  },
  {
    path: '/delivery/pick-list',
    name: 'delivery-pick-lists',
    component: () => import(/* webpackChunkName: "deliveryPickLists" */ '../pages/delivery/PagePickLists'),
    meta: {
      title: 'Pick Lists',
      requiresAuth: true
    }
  },
  {
    path: '/delivery/packing-slips',
    name: 'delivery-packing-slips',
    component: () => import(/* webpackChunkName: "deliveryPackingSlips" */ '../pages/delivery/PagePackingSlips'),
    meta: {
      title: 'Packing Slips',
      requiresAuth: true
    }
  },
  {
    path: '/delivery/shippings',
    name: 'delivery-shippings',
    component: () => import(/* webpackChunkName: "deliveryShippings" */ '../pages/delivery/PageShippings'),
    meta: {
      title: 'Shippings',
      requiresAuth: true
    }
  },
  {
    path: '/delivery/returns',
    name: 'delivery-returns',
    component: () => import(/* webpackChunkName: "deliveryReturns" */ '../pages/delivery/PageReturns'),
    meta: {
      title: 'Returns',
      requiresAuth: true
    }
  },
  {
    path: '/stocks/item',
    name: 'stocks-item',
    component: () => import(/* webpackChunkName: "stocksItem" */ '../pages/stocks/PageItem'),
    meta: {
      title: 'Item',
      requiresAuth: true
    }
  },
  {
    path: '/stocks/item-group',
    name: 'stocks-item-group',
    component: () => import(/* webpackChunkName: "stocksItemGroup" */ '../pages/stocks/PageItemGroup'),
    meta: {
      title: 'Item Group',
      requiresAuth: true
    }
  },
  {
    path: '/stocks/warehouse',
    name: 'stocks-warehouse',
    component: () => import(/* webpackChunkName: "stocksWarehouse" */ '../pages/stocks/PageWarehouse'),
    meta: {
      title: 'Warehouse',
      requiresAuth: true
    }
  },
  {
    path: '/stocks/stock-reconciliation',
    name: 'stock-reconciliation',
    component: () => import(/* webpackChunkName: "stockReconciliation" */ '../pages/stocks/PageStockReconciliation'),
    meta: {
      title: 'Stock Reconciliation',
      requiresAuth: true
    }
  },
  {
    path: '/stocks/quality-inspection',
    name: 'stocks-quality-inspection',
    component: () => import(/* webpackChunkName: "stocksQualityInspection" */ '../pages/stocks/PageQualityInspection'),
    meta: {
      title: 'Quality Inspection',
      requiresAuth: true
    }
  },
  {
    path: '/tools/import',
    name: 'tools-import',
    component: () => import(/* webpackChunkName: "toolsImport" */ '../pages/tools/PageImport'),
    meta: {
      title: 'Import',
      requiresAuth: true
    }
  },
  {
    path: '/tools/export',
    name: 'tools-export',
    component: () => import(/* webpackChunkName: "toolsExport" */ '../pages/tools/PageExport'),
    meta: {
      title: 'Export',
      requiresAuth: true
    }
  },
  {
    path: '/reports/costs',
    name: 'reports-costs',
    component: () => import(/* webpackChunkName: "reportsCosts" */ '../pages/reports/PageCosts'),
    meta: {
      title: 'Costs',
      requiresAuth: true
    }
  },
  {
    path: '/reports/deliveries',
    name: 'reports-deliveries',
    component: () => import(/* webpackChunkName: "reportsDeliveries" */ '../pages/reports/PageDeliveries'),
    meta: {
      title: 'Deliveries',
      requiresAuth: true
    }
  },
  {
    path: '/settings/overview',
    name: 'settings-overview',
    component: () => import(/* webpackChunkName: "settings" */ '../pages/settings/PageOverview'),
    meta: {
      title: 'Overview',
      requiresAuth: true
    }
  },
  {
    path: '/settings/system',
    name: 'settings-system',
    component: () => import(/* webpackChunkName: "settingsSystem" */ '../pages/settings/PageSystem'),
    meta: {
      title: 'System',
      requiresAuth: true
    }
  },
  {
    path: '/settings/carrier',
    name: 'settings-carrier',
    component: () => import(/* webpackChunkName: "settingsCarrier" */ '../pages/settings/PageCarrier'),
    meta: {
      title: 'Carrier',
      requiresAuth: true
    }
  },
  {
    path: '/settings/carriers/dpdhl/portokasse',
    name: 'settings-carriers-dpdhl-portokasse',
    component: () => import(/* webpackChunkName: "settingsCarrierDPagePortokasse" */ '../pages/settings/carriers/dpdhl/PagePortokasse'),
    meta: {
      title: 'Portokasse | DPDHL | Carriers',
      requiresAuth: true
    }
  },
  {
    path: '/settings/carriers/dpdhl/internetmarke',
    name: 'settings-carriers-dpdhl-internetmarke',
    component: () => import(/* webpackChunkName: "settingsCarrierDpdhlInternetmarke" */ '../pages/settings/carriers/dpdhl/PageInternetmarke'),
    meta: {
      title: 'Internetmarke | DPDHL | Carriers',
      requiresAuth: true
    }
  },
  {
    path: '/settings/carriers/dpdhl/packet-plus',
    name: 'settings-carriers-dpdhl-packet-plus',
    component: () => import(/* webpackChunkName: "settingsCarrierDPagePacketPlus" */ '../pages/settings/carriers/dpdhl/PagePacketPlus'),
    meta: {
      title: 'Packet Plus | DPDHL | Carriers',
      requiresAuth: true
    }
  },
  {
    path: '/settings/carrier/dp-internetmarke',
    name: 'dp-internetmarke',
    component: () => import(/* webpackChunkName: "dpInternetmarke" */ '../pages/settings/PageDPInternetmarke'),
    meta: {
      title: 'DP Internetmarke',
      requiresAuth: true
    }
  },     
  {
    path: '/settings/integrations',
    name: 'settings-integrations',
    component: () => import(/* webpackChunkName: "settingsIntegrations" */ '../pages/settings/PageIntegrations'),
    meta: {
      title: 'Integrations',
      requiresAuth: true
    }
  },
  {
    path: '/settings/notifications',
    name: 'settings-notifications',
    component: () => import(/* webpackChunkName: "settingsNotifications" */ '../pages/settings/PageNotifications'),
    meta: {
      title: 'Notifications',
      requiresAuth: true
    }
  },
  {
    path: '/settings/wms',
    name: 'settings-wms',
    component: () => import(/* webpackChunkName: "settings-wms" */ '../pages/settings/PageWMS'),
    meta: {
      title: 'WMS',
      requiresAuth: true
    }
  },
  {
    path: '/security/users',
    name: 'security-users',
    component: () => import(/* webpackChunkName: "security" */ '../pages/security/users/PageActiveUsers'),
    meta: {
      title: 'Security',
      requiresAuth: true
    }
  },
  {
    path: '/security/users/contacts',
    name: 'security-users-contacts',
    component: () => import(/* webpackChunkName: "securityUsersContacts" */ '../pages/security/users/PageContacts'),
    meta: {
      title: 'Contacts',
      requiresAuth: true
    }
  },
  {
    path: '/security/users/guests',
    name: 'security-users-guests',
    component: () => import(/* webpackChunkName: "securityUsersGuests" */ '../pages/security/users/PageGuests'),
    meta: {
      title: 'Guests',
      requiresAuth: true
    }
  },
  {
    path: '/security/users/inactive-users',
    name: 'security-users-inactive-users',
    component: () => import(/* webpackChunkName: "securityUsersInactiveUsers" */ '../pages/security/users/PageInactiveUsers'),
    meta: {
      title: 'Inactive Users',
      requiresAuth: true
    }
  },
  {
    path: '/security/groups',
    name: 'security-groups',
    component: () => import(/* webpackChunkName: "securityGroups" */ '../pages/security/PageGroups'),
    meta: {
      title: 'Groups',
      requiresAuth: true
    }
  },
  {
    path: '/security/groups/inactive-groups',
    name: 'security-inactive-groups',
    component: () => import(/* webpackChunkName: "securityInactiveGroups" */ '../pages/security/groups/PageInactiveGroups'),
    meta: {
      title: 'Inactive Groups',
      requiresAuth: true
    }
  },
  {
    path: '/security/roles',
    name: 'security-roles',
    component: () => import(/* webpackChunkName: "securityRoles" */ '../pages/security/PageRoles'),
    meta: {
      title: 'Roles',
      requiresAuth: true
    }
  },
  {
    path: '/support/how-to',
    name: 'support-how-to',
    component: () => import(/* webpackChunkName: "support" */ '../pages/support/PageHowTo'),
    meta: {
      title: 'Support',
      requiresAuth: true
    }
  },
  {
    path: '/support/faq',
    name: 'support-faq',
    component: () => import(/* webpackChunkName: "supportFaq" */ '../pages/support/PageFAQ'),
    meta: {
      title: 'FAQ',
      requiresAuth: true
    }
  },
  {
    path: '/support/troubleshooting',
    name: 'support-troubleshooting',
    component: () => import(/* webpackChunkName: "supportTroubleshooting" */ '../pages/support/PageTroubleshooting'),
    meta: {
      title: 'Troubleshooting',
      requiresAuth: true
    }
  },
  {
    path: '/account/summary',
    name: 'account-summary',
    component: () => import(/* webpackChunkName: "accountSummary" */ '../pages/account/PageSummary'),
    meta: {
      title: 'Summary',
      requiresAuth: true
    }
  },
  {
    path: '/account/products-and-subscriptions',
    name: 'account-products-and-subscriptions',
    component: () => import(/* webpackChunkName: "accountProductsAndSubscriptions" */ '../pages/account/PageProductsAndSubscriptions'),
    meta: {
      title: 'Products & Subscriptions',
      requiresAuth: true
    }
  },
  {
    path: '/account/billing',
    name: 'account-billing',
    component: () => import(/* webpackChunkName: "accountBilling" */ '../pages/account/PageBilling'),
    meta: {
      title: 'Billing',
      requiresAuth: true
    }
  },
  {
    path: '/account/payment-methods',
    name: 'account-payment-methods',
    component: () => import(/* webpackChunkName: "accountPaymentMethods" */ '../pages/account/PagePaymentMethods'),
    meta: {
      title: 'Payment Methods',
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "accountPaymentMethods" */ '../pages/profile/PageProfile'),
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },   
  {
    path: '/profile/preferences',
    name: 'profile-preferences',
    component: () => import(/* webpackChunkName: "accountPaymentMethods" */ '../pages/profile/PagePreferences'),
    meta: {
      title: 'Preferences',
      requiresAuth: true
    }
  },   
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import(/* webpackChunkName: "sign-in" */ '../pages/PageSignIn'),
    meta: {
      title: 'Sign In',
      requiresAuth: false
    }
  },
  {
    path: '/password/forgot',
    name: 'password-forgot',
    component: () => import(/* webpackChunkName: "password-forgot" */ '../pages/password/PagePasswordForgot'),
    meta: {
      title: 'Forgot password?',
      requiresAuth: false
    }
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../pages/password/PagePasswordReset'),
    meta: {
      title: 'Reset password',
      requiresAuth: false
    }
  },
]
const router = new VueRouter({
  mode: 'history',
  routes
});

export default router

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.state.forkLane.user === null) {
      next({ path: '/sign-in' })
    } else {
      next() // go to wherever I'm going
    }
  }

  // Redirect all HOME links to the Dashboard Overview page
  if (to.matched.some(record => record.name === 'home')) {
    next({ name: 'dashboard-overview' });
  }

  next() // does not require auth, make sure to always call next()!
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    let title = to.meta.title;

    if (title) {
      title += ' | ' + process.env.VUE_APP_TITLE;
    } else {
      title = process.env.VUE_APP_TITLE;
    }

    document.title = title;
  });
});