import i18n from '@/i18n';

const initialState = {
    open: true,
    width: '224px',
    content: [
        {
            name: i18n.tc('dashboard'),
            collapsed: false,
            items: [
                {
                    name: i18n.tc('overview'),
                    route: 'dashboard-overview',
                    icon: 'tachometer-alt'
                }
            ]
        },
        {
            name: i18n.tc('delivery'),
            collapsed: false,
            items: [
                {
                    name: i18n.tc('sales-orders'),
                    route: 'delivery-sales-orders',
                    icon: 'shopping-cart'
                },
                {
                    name: i18n.tc('delivery-notes'),
                    route: 'delivery-notes',
                    icon: 'file-alt'
                },
                {
                    name: i18n.tc('pick-lists'),
                    route: 'delivery-pick-lists',
                    icon: 'barcode'
                },
                {
                    name: i18n.tc('packing-slips'),
                    route: 'delivery-packing-slips',
                    icon: 'box-open'
                },
                {
                    name: i18n.tc('shipping', 2),
                    route: 'delivery-shippings',
                    icon: 'shipping-fast'
                },
                {
                    name: i18n.tc('returns'),
                    route: 'delivery-returns',
                    icon: 'undo-alt'
                }
            ]
        },
        {
            name: i18n.tc('stock'),
            collapsed: false,
            items: [
                {
                    name: i18n.tc('item'),
                    route: 'stocks-item',
                    icon: 'cube'
                },
                {
                    name: i18n.tc('item-group'),
                    route: 'stocks-item-group',
                    icon: 'cubes'
                },
                {
                    name: i18n.tc('warehouse'),
                    route: 'stocks-warehouse',
                    icon: 'warehouse'
                }
            ]
        },
        {
            name: i18n.tc('reports'),
            collapsed: false,
            items: [
                {
                    name: i18n.tc('costs'),
                    route: 'reports-costs',
                    icon: 'money-bill-alt'
                },
                {
                    name: i18n.tc('deliveries'),
                    route: 'reports-deliveries',
                    icon: 'copy'
                }
            ]
        },
        {
            name: i18n.tc('tools'),
            collapsed: true,
            items: [
                {
                    name: i18n.tc('import'),
                    route: 'tools-import',
                    icon: 'file-import'
                },
                {
                    name: i18n.tc('export'),
                    route: 'tools-export',
                    icon: 'file-export'
                },
                {
                    name: i18n.tc('stock-reconciliation'),
                    route: 'stock-reconciliation',
                    icon: 'tasks'
                }
            ]
        },
        {
            name: i18n.tc('settings'),
            collapsed: true,
            items: [
                {
                    name: i18n.tc('system'),
                    route: 'settings-system',
                    icon: 'wrench'
                },
                {
                    name: i18n.tc('carrier', 2),
                    route: 'settings-carrier',
                    icon: 'truck-loading'
                },
                {
                    name: i18n.tc('integrations'),
                    route: 'settings-integrations',
                    icon: 'project-diagram'
                },
                {
                    name: i18n.tc('notifications'),
                    route: 'settings-notifications',
                    icon: 'mail-bulk'
                },
                {
                    name: i18n.tc('wms'),
                    route: 'settings-wms',
                    icon: 'layer-group'
                }
            ]
        },
        {
            name: i18n.tc('security'),
            collapsed: true,
            items: [
                {
                    name: i18n.tc('users'),
                    route: 'security-users',
                    icon: 'user'
                },
                {
                    name: i18n.tc('groups'),
                    route: 'security-groups',
                    icon: 'users'
                },
                {
                    name: i18n.tc('roles'),
                    route: 'security-roles',
                    icon: 'user-cog'
                },
            ]
        },
        {
            name: i18n.tc('account'),
            collapsed: true,
            items: [
                {
                    name: i18n.tc('summary'),
                    route: 'account-summary',
                    icon: 'building'
                },
                {
                    name: i18n.tc('product', 2),
                    route: 'account-products-and-subscriptions',
                    icon: 'list-ul'
                },
                {
                    name: i18n.tc('billing'),
                    route: 'account-billing',
                    icon: 'file-invoice'
                },
                {
                    name: i18n.tc('payment-methods'),
                    route: 'account-payment-methods',
                    icon: 'credit-card'
                },
            ]
        },
    ]
}

export const SidebarModule = {
    namespaced: true,
    state: initialState,
    mutations: {
        toggleSidebar(state) {
            state.open = !state.open;
            if (state.width === '224px') {
                state.width = '45px';
            } else {
                state.width = '224px'
            }
        },
        openSidebar(state, width) {
            state.open = true;
            state.width = width;
        },
        closeSidebar(state, width) {
            state.open = false;
            state.width = width;
        },
        toggleGroup(state, index) {
            state.content[index].collapsed = !state.content[index].collapsed;
        }
    }
}