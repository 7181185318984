import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

export default function createInfoObject(type, feature, mode, data) {
    return {
        header: {
            version: '1.0.2',
            id: uuidv4(),
            parentId: '',
            type: 'INFO_OBJECT_DEFAULT',
            status: 'NEW',
            history: [
                {
                step: '1',
                timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                unit: uuidv4(),
                type: 'SERVICE_REQUEST',
                status: 'NEW'
                }
            ]
        },
        payload: {
            type: type,
            feature: feature,
            mode: mode,
            data: data
        }
    }
}