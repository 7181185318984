import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/assets/scss/theme.scss';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import i18n from '@/i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import Gravatar from 'vue-gravatar';
import moment from "moment";
import VueCurrencyFilter from "vue-currency-filter";
import {
  faCreditCard,
  faListUl,
  faBuilding,
  faUser,
    faUsers,
    faUserCog,
  faLayerGroup,
  faMailBulk,
  faProjectDiagram,
  faTruckLoading,
  faWrench,
  faCopy,
  faMoneyBillAlt,
  faFileInvoice,
  faTasks,
  faFileImport,
  faFileExport,
  faWarehouse,
  faCube,
  faCubes,
  faFileAlt,
  faBarcode,
  faBoxOpen,
  faUndoAlt,
  faShippingFast,
  faShoppingCart,
  faInfo,
  faLanguage,
  faSearch,
  faTachometerAlt,
  faCircle,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPen,
  faChevronDown,
  faChevronUp,
  faCaretDown,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import {
  faBell,
  faQuestionCircle
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faCreditCard,
    faListUl,
    faBuilding,
    faUser,
    faUsers,
    faUserCog,
    faLayerGroup,
    faMailBulk,
    faProjectDiagram,
    faTruckLoading,
    faWrench,
    faCopy,
    faMoneyBillAlt,
    faFileInvoice,
    faTasks,
    faFileImport,
    faFileExport,
    faWarehouse,
    faCube,
    faCubes,
    faFileAlt,
    faBarcode,
    faBoxOpen,
    faUndoAlt,
    faShippingFast,
    faShoppingCart,
    faInfo,
    faLanguage,
    faBell,
    faQuestionCircle,
    faSearch,
    faTachometerAlt,
    faCircle,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faPen,
    faChevronDown,
    faChevronUp,
    faCaretDown,
    faCaretUp,
    faChevronLeft,
    faChevronRight,
    faTimes
);

Vue.config.productionTip = false

Vue.use(VueCurrencyFilter, [
{
  symbol : '€',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
},
{
  name: 'currency_2',
  symbol : '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
}
]);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-gravatar', Gravatar);

Vue.filter('timestamp', date => moment(date).format('DD.MM.YYYY, HH:MM'));

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    const html = document.documentElement;
    html.setAttribute('lang', i18n.locale.substring(0, 2));
    window.addEventListener("resize", this.setSidebar);
    this.setSidebar();
  },
  methods: {
    setSidebar() {
        if (window.innerWidth < 768) {
            this.$store.commit('sidebar/closeSidebar', '0px')
        } else if (window.innerWidth < 1200) {
            this.$store.commit('sidebar/closeSidebar', '45px')
        } else {
            this.$store.commit('sidebar/openSidebar', '224px')
        }
    }
}
}).$mount('#app')
