import { ForkLaneModule } from "@/modules/fork-lane.module";

export default function getRequestHeaders() {
    let headers = {
        'Content-Type': 'application/json',
    };

    if (ForkLaneModule.state.user && ForkLaneModule.state.user.token) {
        headers['Authorization'] = 'Bearer ' + ForkLaneModule.state.user.token;
    }

    return headers;
}